<div class="flex min-w-0 grow flex-col">
    <div class="mb-8 mt-4 flex min-w-0 grow gap-8">
        <span class="w-160 text-text-mid-contrast typo-sm shrink-0">{{
            label
        }}</span>
        <div class="typo-sm flex min-w-0 grow">
            <ng-content></ng-content>
        </div>
    </div>
    <interacta-separator
        *ngIf="!hideSeparator"
        thickness="soft"
    ></interacta-separator>
</div>
