import { NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { SeparatorComponent } from '../separator/separator.component';

@Component({
    selector: 'interacta-key-value-field',
    templateUrl: './key-value-field.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, SeparatorComponent],
})
export class KeyValueFieldComponent {
    @Input() label = '';
    @Input() hideSeparator = false;

    @Output() toggleCollapsed = new EventEmitter();
}
